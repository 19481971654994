<template>
  <section>
    <b-overlay :show="loading">
      <validation-observer
        ref="refFormObserver"
        #default="{ handleSubmit, invalid }"
      >
        <b-form @submit.prevent="handleSubmit(onSubmit)">
          <b-row>
            <!-- Col: Left (pedido Container) -->
            <b-col
              cols="12"
              lg="10"
              xl="10"
              md="9"
            >
              <b-card
                no-body
                :aria-hidden="loading ? 'true' : null"
              >
                <!-- Head er -->
                <b-card-body class="pb-0">
                  <div
                    class="d-flex justify-content-between flex-md-row flex-column mt-0"
                  >
                    <!-- Header: Left Content -->
                    <div>
                      <h3
                        v-if="pedidoProveedorData.codigo"
                        class="text-primary"
                      >
                        Codigo #{{ pedidoProveedorData.codigo }}
                      </h3>

                      <h3
                        v-else
                        class="text-primary"
                      >
                        Nuevo pedido a proveedor
                      </h3>

                      <validation-provider
                        #default="validationContext"
                        name="Proveedor"
                        rules="required"
                      >
                        <b-form-group
                          label="Proveedor*"
                          label-for="Proveedor"
                          :state="getValidationState(validationContext)"
                        >
                          <v-select
                            v-model="pedidoProveedorData.fk_proveedor"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="proveedores || []"
                            :clearable="false"
                            class="item-selector-title"
                            placeholder="Selecionar"
                            :reduce="(val) => val.id"
                            label="nombre_comercial"
                            :disabled="cantEditPedido || hasDetallesFromPedido"
                            @input="pedidoItems = []"
                          />
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                          >
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </div>

                    <!-- Header: Right Content -->
                    <div class="mt-md-0 mt-2">
                      <b-row
                        align-h="end"
                        class="pr-1 mb-2"
                      >
                        <b-badge
                          :variant="statusVariant(pedidoProveedorData.estado)"
                        >{{
                          statusLabel(pedidoProveedorData.estado)
                        }}</b-badge>
                      </b-row>
                      <div class="d-flex align-items-center mb-1">
                        <span class="title pr-1 pb-1"> Fecha*: </span>
                        <validation-provider
                          #default="validationContext"
                          name="Fecha"
                          rules="required"
                        >
                          <b-form-group
                            :state="getValidationState(validationContext)"
                          >
                            <flat-pickr
                              v-if="!cantEditPedido"
                              v-model="pedidoProveedorData.fecha"
                              placeholder="dd-mm-yyyy"
                              class="form-control"
                              :config="{
                                allowInvalidPreload: true,
                                format: 'd-m-Y',
                                altFormat: 'd-m-Y',
                                allowInput: true,
                                altInput: true,
                                locale: Spanish,
                                disable: [
                                  function (date) {
                                    return date < new Date();
                                  },
                                ],
                              }"
                              :disabled="cantEditPedido"
                            />
                            <span v-else>{{ formatDate(pedidoProveedorData.fecha) }}</span>
                            <b-form-invalid-feedback
                              :state="getValidationState(validationContext)"
                            >
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </div>
                      <b-row
                        align-h="end"
                        class="mx-0"
                      >
                        <b-form-checkbox
                          v-if="!cantEditPedido"
                          v-model="pedidoProveedorData.is_urgent"
                        >Urgente</b-form-checkbox>
                      </b-row>
                      <b-row
                        v-if="pedidoProveedorData.fecha_enviado"
                        align-h="end"
                        class="mx-0 mt-2"
                      > Pedido enviado el {{ formatDate(null, pedidoProveedorData.fecha_enviado) }}h.
                      </b-row>
                    </div>
                  </div>
                </b-card-body>

                <!-- Spacer -->
                <hr>

                <h3 class="pl-2 mb-1">
                  Detalles del pedido
                </h3>

                <b-table
                  :items="pedidoItems"
                  responsive
                  striped
                  :fields="tableHeaders"
                >
                  <!-- Ref Prod -->
                  <template #cell(refProd)="data">
                    <span>{{ data.item.refProd }}</span>
                  </template>
                  <!-- Descripcion -->
                  <template #cell(descripcion)="data">
                    <span>{{ data.item.descripcion }}</span>
                  </template>

                  <!-- Coste -->
                  <template #cell(coste)="data">
                    <span>{{ data.item.coste }}€</span>
                  </template>

                  <!-- Coste Total -->
                  <template #cell(coste_total)="data">
                    <span>{{ calculateCosteTotal(data.item.unidades, data.item.coste) }}€</span>
                  </template>

                  <!-- Pedido From -->
                  <template #cell(pedido_codigo)="data">
                    <router-link
                      v-if="data.item.fk_pedido_from"
                      :to="{ name: 'pedido-edit', params: { id: data.item.fk_pedido_from } }"
                    >
                      <slot>{{ data.item.pedido_codigo }}</slot>
                    </router-link>
                    <span v-else>-</span>
                  </template>

                  <!-- Cantidad -->
                  <template #cell(unidades)="data">
                    <validation-provider
                      #default="validationContext"
                      name="Cantidad"
                      rules="required|min_value:1"
                    >
                      <b-form-group
                        :state="getValidationState(validationContext)"
                      >
                        <b-form-input
                          v-model="data.item.unidades"
                          type="number"
                          :disabled="cantEditPedido"
                        />
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                        >
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </template>

                  <!-- Column: Actions -->
                  <template #cell(actions)="data">
                    <!-- Eliminar -->
                    <div v-if="!cantEditPedido && !cantEditDetalle(data.item)">
                      <b-button
                        :id="`delete-${data.item.id}`"
                        size="sm"
                        variant="danger"
                        class="btn-icon"
                        @click="removeItem(data.index, data.item)"
                      >
                        <feather-icon icon="XIcon" />
                      </b-button>
                      <b-tooltip
                        :target="`delete-${data.item.id}`"
                        triggers="hover"
                        placement="bottom"
                        boundary="window"
                      >
                        <b>Eliminar</b>
                      </b-tooltip>
                    </div>
                  </template>
                </b-table>

                <b-row class="px-1">
                  <!-- Items Section -->
                  <b-card-body class="form-item-section">
                    <b-button
                      v-if="!cantEditPedido"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      size="sm"
                      variant="primary"
                      @click="selectMaterialModal = true"
                    >
                      Añadir Material
                    </b-button>
                  </b-card-body>

                  <b-card-body v-if="user.fk_role === 1">
                    <h4>
                      <strong>Total:</strong>
                      {{ calculateTotal() }}€
                    </h4>
                  </b-card-body>
                </b-row>

                <!-- Spacer -->
                <hr>

                <!-- Note -->
                <b-card-body class="pedido-padding pt-0">
                  <span class="font-weight-bold">Nota: </span>
                  <b-form-textarea
                    v-model="pedidoProveedorData.nota"
                    :disabled="cantEditPedido"
                  />
                </b-card-body>
              </b-card>
            </b-col>

            <!-- Right Col: Card -->
            <b-col
              cols="12"
              lg="2"
              md="3"
              xl="2"
              class="pedido-actions mt-md-0 mt-2"
            >
              <!-- Action Buttons -->
              <b-card>
                <h5
                  v-if="pedidoProveedorData.estado === 'cancelado'"
                  class="text-center"
                >
                  Pedido cancelado!
                </h5>

                <!-- Button: Save pedido -->
                <b-button
                  v-if="!cantEditPedido"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mb-75"
                  block
                  type="submit"
                  :disabled="invalid"
                >
                  {{
                    pedidoProveedorData.estado === "borrador"
                      ? "Guardar borrador"
                      : "Guardar"
                  }}
                </b-button>

                <!-- Button: Send pedido -->
                <b-button
                  v-if="
                    $route.params.id !== 'new' &&
                      pedidoProveedorData.estado === 'borrador'
                  "
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mb-75"
                  block
                  :disabled="invalid"
                  @click="sendPedidoToEvaluation()"
                >
                  Guardar y enviar
                </b-button>

                <!-- Button: Imprimir -->
                <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-info"
                  block
                  @click="imprimirPedido(pedidoProveedorData.id)"
                >
                  <feather-icon
                    icon="PrinterIcon"
                    class="mr-1"
                  />Imprimir
                </b-button>

                <!-- Button: Delete -->
                <b-button
                  v-if="pedidoProveedorData.estado === 'borrador'"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="danger"
                  block
                  @click="deleteModal = true"
                >
                  Eliminar
                </b-button>
              </b-card>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>
    <b-modal
      v-model="selectMaterialModal"
      :size="isMovil ? ' select-material-movil' : 'xl'"
      title="Seleccionar material"
      hide-footer
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
    >
      <b-row
        align-v="start"
        class="ml-0 pl-0"
      >
        <b-col
          cols="12"
          lg="2"
          md="6"
          sm="12"
          xs="12"
        >
          <b-form-select
            id="perPageSelect"
            v-model="materialPerPage"
            :options="materialPerPageOptions"
          />
        </b-col>
        <b-col
          cols="12"
          lg="6"
          md="6"
          sm="12"
          xs="12"
        >
          <b-form-input
            id="filterInput"
            v-model="searchMaterial"
            placeholder="Buscar..."
            class="form-control-merge"
            type="search"
          />
        </b-col>
        <b-col
          cols="12"
          lg="4"
          md="6"
          sm="12"
          xs="12"
        >
          <b-row
            align-h="end"
            class="mx-0 px-0"
          >
            <b-col
              cols="12"
              lg="6"
            >
              <b-button
                v-if="isMovil"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                block
                variant="warning"
                class="btn-icon"
                @click="selectMaterialModal = false"
              >
                <feather-icon icon="XIcon" />
              </b-button>
              <b-button
                v-else
                block
                variant="danger"
                @click="selectMaterialModal = false"
              >
                Cancelar
              </b-button>
            </b-col>
            <b-col
              cols="12"
              lg="6"
            >
              <b-button
                v-if="isMovil"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-icon"
                block
                @click="addNewItemInItemForm"
              >
                <feather-icon icon="SaveIcon" />
              </b-button>
              <b-button
                v-else
                block
                variant="primary"
                @click="addNewItemInItemForm"
              >
                Guardar
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="mx-0 px-0 pt-2">
        <b-col
          cols="12"
          lg="5"
          md="5"
          sm="6"
          xs="12"
        >
          <v-select
            v-model="proveedorFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="proveedores"
            :reduce="(val) => val.id"
            :clearable="false"
            class="mb-1 item-selector-title"
            placeholder="Proveedor"
            label="nombre_comercial"
          />
        </b-col>
        <b-col
          cols="12"
          lg="5"
          md="5"
          sm="6"
          xs="12"
        >
          <v-select
            v-model="tipoFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="tipos"
            :clearable="false"
            class="mb-1 item-selector-title"
            placeholder="Tipo Material"
          />
        </b-col>
        <b-col
          cols="12"
          lg="2"
          md="2"
          sm="12"
          xs="12"
        >
          <b-button
            block
            variant="outline-danger"
            @click="clearMaterialFilter"
          >
            Limpar Filtro
          </b-button>
        </b-col>
      </b-row>
      <b-table
        :items="materialesForTableFilter"
        responsive
        :fields="materialTableHeaders"
        :current-page="currentPageMaterial"
        :per-page="materialPerPage"
        class="position-relative rows-responsive"
        selectable
        select-mode="multi"
        select-variant="light"
        :tbody-tr-class="rowClass"
        :filter="searchMaterial"
        stacked="sm"
        @row-clicked="onRowSelected"
      >
        <template #cell(selected)="data">
          <template v-if="data.item.selected">
            <feather-icon
              size="20"
              icon="CheckCircleIcon"
            />
          </template>

          <template v-else>
            <feather-icon
              size="20"
              icon="CircleIcon"
            />
          </template>
        </template>

        <!-- Column: Codigo -->
        <template #cell(codigo)="data">
          #{{ data.item.codigo }}
        </template>
        <!-- Column: Descripción -->
        <template #cell(descripcion)="data">
          {{
            data.item.descripcion_custom
              ? data.item.descripcion_custom
              : data.item.descripcion
          }}
        </template>

        <!-- Column: Coste -->
        <template #cell(coste)="data">
          <span>{{ data.item.coste }}€</span>
        </template>

        <!-- Column: Estado -->
        <template #cell(estado)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.estado)}`"
            class="text-capitalize"
          >
            {{ resolveStatusText(data.item.estado) }}
          </b-badge>
        </template>

        <!-- Cantidad -->
        <template #cell(unidades)="data">
          <validation-provider
            #default="validationContext"
            name="Cantidad"
            rules="min_value:1"
          >
            <b-form-group :state="getValidationState(validationContext)">
              <b-form-input
                v-model="data.item.unidades"
                placeholder="Units"
                type="number"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </template>
      </b-table>

      <b-pagination
        v-model="currentPageMaterial"
        :total-rows="materialesForTableFilter.length"
        :per-page="materialPerPage"
        first-number
        last-number
        class="mb-0 mt-1 mt-sm-0"
        prev-class="prev-item"
        next-class="next-item"
      />
    </b-modal>
    <ConfirmationModal
      :id="`confirmation-${$route.params.id}`"
      :show="deleteModal"
      message="Esta acción no se puede revertir y se perderá el pedido."
      title="¿Está seguro de que desea eliminar el pedido?"
      @action="deletePedido($route.params.id)"
      @close="deleteModal = false"
    />

    <AlertModal
      :id="`alert-${$route.params.id}`"
      :show="alertModal"
      :message="alertMsg"
      :title="alertTitle"
      variant="danger"
      :is-ok-only="true"
      @close="alertModal = false"
      @action="alertModal = false"
    />
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BFormCheckbox,
  BPopover,
  VBToggle,
  BBadge,
  BTable,
  BTooltip,
  BFormInvalidFeedback,
  BOverlay,
  BLink,
  BModal,
  BPagination,
  BFormSelect,
} from 'bootstrap-vue'
import Logo from '@core/layouts/components/Logo.vue'
import { ref, onUnmounted } from '@vue/composition-api'

import { Spanish } from 'flatpickr/dist/l10n/es'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import useMaterialesList from '@/views/almacen/materiales/useMaterialesList'
import materialesStoreModule from '@/views/almacen/materiales/materialesStoreModule'
import { pedidos } from '@/utils/pedidos'
import router from '@/router'
import ConfirmationModal from '@/components/ConfirmationModal.vue'
import albaranesStoreModule from '@/views/almacen/albaranes/albaranesStoreModule'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import AlertModal from '@/components/AlertModal.vue'
import moment from 'moment'
import useProveedoresList from '@/views/system/proveedor/useProveedoresList'
import proveedorStoreModule from '@/views/system/proveedor/proveedorStoreModule'
import PedidoProveedor from '@/models/PedidoProveedor'
import PedidoProveedorDetalle from '@/models/PedidoProveedorDetalle'
import { isMobile } from 'mobile-device-detect'
import { materiales } from '@/utils/materiales'
import pedidosStoreModule from './pedidosProveedoresStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BFormCheckbox,
    BBadge,
    BPopover,
    BTable,
    BTooltip,
    BLink,
    flatPickr,
    vSelect,
    Logo,
    ConfirmationModal,
    BFormInvalidFeedback,
    AlertModal,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BModal,
    BPagination,
    BFormSelect,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      Spanish,
      deleteModal: false,
      selectedItem: {},
      selected: [],
      selectMaterialModal: false,
      materialTableHeaders: [
        { key: 'selected', label: '', sortable: true },
        { key: 'codigo', label: 'Código', sortable: true },
        { key: 'descripcion', label: 'Descripción', sortable: true },
        { key: 'unidades', label: 'Cantidad', sortable: false },
        { key: 'coste', label: 'Coste', sortable: true },
        { key: 'stock', label: 'Stock', sortable: true },
        { key: 'tipo', label: 'Tipo Material', sortable: true },
        { key: 'medida', label: 'Medida', sortable: true },
        { key: 'estado', label: 'Estado', sortable: true },
      ],
      currentPageMaterial: 1,
      filterMaterial: null,
      searchMaterial: '',
      materialPerPage: 10,
      materialPerPageOptions: [10, 20, 50],
      isMovil: isMobile,
      proveedorFilter: null,
      tipoFilter: null,
      tipos: materiales.tipos,
      required,
      alertModal: false,
      alertMsg: '',
      alertTitle: '',
    }
  },
  computed: {
    hasDetallesFromPedido() {
      if (this.pedidoItems.find(el => el.fk_pedido_from)) return true
      return false
    },
    user() {
      return this.$store.state.auth.user
    },
    materialesByProveedor() {
      if (this.pedidoProveedorData.fk_proveedor) {
        return this.materiales.filter(
          el => el.fk_proveedor === this.pedidoProveedorData.fk_proveedor,
        )
      }
      return this.materiales
    },
    cantEditPedido() {
      if (this.user.fk_role === 1) {
        if (
          this.pedidoProveedorData.estado !== 'borrador'
          && this.pedidoProveedorData.estado !== 'pendiente'
        ) return true
        return false
      }
      if (this.pedidoProveedorData.estado !== 'borrador') return true
      return false
    },
    isAdmin() {
      return this.user.fk_role === 1
    },
    isOperario() {
      return this.user.fk_role === 2
    },
    loading() {
      return this.$store.state['app-pedidos-proveedores'].loading
    },
    materialesForTableFilter() {
      let filterItems = []
      if (this.materiales) {
        filterItems = this.materiales.filter(
          material => material.descripcion
            ?.toLowerCase()
            .includes(this.searchMaterial.toLowerCase())
                || material.descripcion_custom
                  ?.toLowerCase()
                  .includes(this.searchMaterial.toLowerCase())
                || material.codigo
                  ?.toLowerCase()
                  .includes(this.searchMaterial.toLowerCase()),
        )

        if (this.tipoFilter) {
          filterItems = filterItems.filter(
            material => material.tipo === this.tipoFilter.value,
          )
        }

        if (this.proveedorFilter) {
          filterItems = filterItems.filter(
            material => material.fk_proveedor === this.proveedorFilter,
          )
        }

        // Adicionar ou definir o valor `unidades` para 1 se não estiver presente
        filterItems = filterItems.map(material => ({
          ...material,
          unidades: material.unidades !== undefined ? material.unidades : 1,
        }))
      }

      return filterItems
    },
  },
  methods: {
    rowClass(item, type) {
      if (!item || type !== 'row') return
      if (item.selected) {
        // eslint-disable-next-line consistent-return
        return 'bg-light'
      }
    },
    onRowSelected(item) {
      // eslint-disable-next-line no-param-reassign
      item.selected = !item.selected
      if (item.selected) {
        this.selected.push(item)
      } else {
        const index = this.selected.findIndex(i => i.id === item.id)
        this.selected.pop(index)
      }
    },
    clearMaterialFilter() {
      this.searchMaterial = ''
      this.proveedorFilter = null
      this.tipoFilter = null
    },
    imprimirPedido(id) {
      this.$store
        .dispatch('app-pedidos-proveedores/printPedidoProveedor', id)
        .then(response => {
          window.open(response.route, '_blank')
        })
        .catch(error => {
          console.error('Error printing the pedido:', error)
        })
    },
    cantEditDetalle(item) {
      if (item.fk_pedido_from) {
        return true
      }
      return false
    },
    calculateCosteTotal(unit, coste) {
      const total = coste * unit
      return total.toFixed(2)
    },
    calculateTotal() {
      let total = 0
      this.pedidoItems.forEach(item => {
        total += item.coste * item.unidades
      })
      return total.toFixed(2)
    },
    async sendPedidoToEvaluation() {
      this.pedidoProveedorData.estado = 'enviado'
      await this.onSubmit()
    },
    deletePedido(id) {
      if (id === 'new') this.$router.push({ name: 'pedidos-proveedores-list' })
      else {
        this.$store
          .dispatch('app-pedidos-proveedores/deletePedidoProveedor', id)
          .then(() => {
            this.$router.push({ name: 'pedidos-proveedores-list' })
          })
      }
    },
    statusLabel(status) {
      return pedidos.estados.find(el => el.value === status).label
    },
    statusVariant(status) {
      return pedidos.estados.find(el => el.value === status).variant
    },
    addNewItemInItemForm() {
      this.selected.forEach(item => {
        item.descripcion = item.descripcion_custom ?? item.descripcion
        item.refProd = item.codigo
        this.pedidoItems.push(item)
      })
      // eslint-disable-next-line no-return-assign,no-param-reassign
      this.materiales.map(m => (m.selected = false))
      this.selected = []
      this.searchMaterial = ''
      this.selectMaterialModal = false
    },
    removeItem(index, item) {
      this.pedidoItems.splice(index, 1)
    },
    async onSubmit() {
      if (this.pedidoItems.length <= 0) {
        this.alertModal = true
        this.alertMsg = 'Debes seleccionar al menos 1 material y 1 unidad'
        this.alertTitle = 'Campos invalidos'
        return
      }

      if (this.$route.params.id === 'new') this.pedidoProveedorData.fk_user_creado = this.user.id

      this.pedidoProveedorData.detalles = []
      this.pedidoItems.forEach(el => {
        this.pedidoProveedorData.detalles.push(
          new PedidoProveedorDetalle({
            fk_material: el.id,
            coste: el.coste,
            unidades: el.unidades,
            fk_pedido_from: el.fk_pedido_from,
          }),
        )
      })

      this.pedidoProveedorData.total_coste = this.calculateTotal()

      // Check if there is a pedido in borrador status
      const existingPedidoProveedor = await this.$store.dispatch(
        'app-pedidos-proveedores/checkIfPedidoExistsForProveedor',
        this.pedidoProveedorData.fk_proveedor,
      )

      if (
        existingPedidoProveedor !== null
        && existingPedidoProveedor.id !== this.pedidoProveedorData.id
      ) {
        // update existing
        existingPedidoProveedor.detalles.forEach(el => {
          this.pedidoProveedorData.detalles.push(
            new PedidoProveedorDetalle({
              fk_pedido_from: el.fk_pedido_from,
              fk_material: el.fk_material,
              coste: el.coste,
              unidades: el.unidades,
              fk_pedido_proveedor: el.fk_pedido_proveedor,
              id: el.id,
            }),
          )
        })
        this.pedidoProveedorData.id = existingPedidoProveedor.id
      }
      this.$store
        .dispatch(
          'app-pedidos-proveedores/addPedidoProveedor',
          this.pedidoProveedorData,
        )
        .then(() => {
          // this.$router.push({ name: 'pedidos-proveedores-list' })
        })
    },
  },
  setup() {
    const PEDIDO_APP_STORE_MODULE_NAME = 'app-pedidos-proveedores'
    const MATERIALES_APP_STORE_MODULE_NAME = 'app-materiales'
    const PEDIDOS_APP_STORE_MODULE_NAME = 'app-albaranes'
    const PROVEEDOR_APP_STORE_MODULE_NAME = 'app-proveedor'

    // Register module
    if (!store.hasModule(PEDIDO_APP_STORE_MODULE_NAME)) store.registerModule(PEDIDO_APP_STORE_MODULE_NAME, pedidosStoreModule)

    if (!store.hasModule(MATERIALES_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        MATERIALES_APP_STORE_MODULE_NAME,
        materialesStoreModule,
      )
    }

    if (!store.hasModule(PEDIDOS_APP_STORE_MODULE_NAME)) {
      store.registerModule(PEDIDOS_APP_STORE_MODULE_NAME, albaranesStoreModule)
    }

    if (!store.hasModule(PROVEEDOR_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        PROVEEDOR_APP_STORE_MODULE_NAME,
        proveedorStoreModule,
      )
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PEDIDO_APP_STORE_MODULE_NAME)) store.unregisterModule(PEDIDO_APP_STORE_MODULE_NAME)

      if (store.hasModule(MATERIALES_APP_STORE_MODULE_NAME)) store.unregisterModule(MATERIALES_APP_STORE_MODULE_NAME)

      if (store.hasModule(PEDIDOS_APP_STORE_MODULE_NAME)) store.unregisterModule(PEDIDOS_APP_STORE_MODULE_NAME)

      if (store.hasModule(PROVEEDOR_APP_STORE_MODULE_NAME)) store.unregisterModule(PROVEEDOR_APP_STORE_MODULE_NAME)
    })

    const materiales = ref(null)
    const proveedores = ref([])

    const { listMateriales, resolveStatusText, resolveStatusVariant } = useMaterialesList()
    const { listProveedores } = useProveedoresList()

    listMateriales().then(response => {
      materiales.value = response
    })
    listProveedores().then(response => {
      proveedores.value = response
    })

    const itemFormBlankItem = ref({
      id: '',
      refProd: '',
      unidades: 1,
      descripcion: '',
      medida: '',
      talla: '',
      coste: 0,
    })

    const pedidoProveedorData = ref(
      new PedidoProveedor({
        total_coste: 0,
        estado: 'borrador',
        fecha: new Date().toISOString().split('T')[0],
      }),
    )

    const pedidoItems = ref([])

    const updateItemForm = (index, val) => {
      const {
        codigo,
        id,
        medida,
        talla,
        descripcion,
        coste,
        fk_pedido_from,
        pedido_codigo,
      } = val
      pedidoItems.value[index].refProd = codigo
      pedidoItems.value[index].id = id
      pedidoItems.value[index].descripcion = descripcion
      pedidoItems.value[index].medida = medida
      pedidoItems.value[index].talla = talla
      pedidoItems.value[index].coste = coste
      pedidoItems.value[index].fk_pedido_from = fk_pedido_from
      pedidoItems.value[index].pedido_codigo = pedido_codigo
    }

    if (router.currentRoute.params.id !== 'new') {
      store
        .dispatch(
          'app-pedidos-proveedores/fetchPedidoProveedor',
          router.currentRoute.params.id,
        )
        .then(async response => {
          pedidoProveedorData.value = response
          const { detalles } = pedidoProveedorData.value

          for (let i = 0; i < detalles.length; i++) {
            const material = await store.dispatch(
              'app-materiales/fetchMaterial',
              detalles[i].fk_material,
            )

            pedidoItems.value.push({
              id: material.id,
              refProd: material.codigo,
              unidades: detalles[i].unidades,
              descripcion: material.descripcion,
              medida: material.medida,
              talla: material.talla,
              coste: material.coste,
              fk_pedido_from: detalles[i].fk_pedido_from,
              pedido_codigo: detalles[i].pedido_codigo,
            })
          }
        })
    }

    const tableHeaders = ref([
      { key: 'refProd', label: 'REF PROD', sortable: false },
      {
        key: 'descripcion', label: 'Descripcion', sortable: false, thStyle: { width: '50%' },
      },
      { key: 'medida', label: 'Tipo Medida', sortable: false },
      { key: 'unidades', label: 'Cantidad', sortable: false },
      { key: 'pedido_codigo', label: 'Cod. Pedido', sortable: false },
    ])

    // Display Coste only if it is admin
    if (store.state.auth.user.fk_role === 1) {
      tableHeaders.value.push(
        { key: 'coste', label: 'Coste Unit.' },
        { key: 'coste_total', label: 'Coste Total' },
      )
    }

    tableHeaders.value.push({ key: 'actions', label: '', thStyle: { minWidth: '120px' } })

    const resetpedidoProveedorData = () => {
      pedidoProveedorData.value = JSON.parse(
        JSON.stringify(
          new PedidoProveedor({
            total_coste: 0,
            estado: 'borrador',
          }),
        ),
      )
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetpedidoProveedorData,
    )

    return {
      pedidoProveedorData,
      updateItemForm,
      itemFormBlankItem,
      pedidoItems,
      resolveStatusVariant,
      resolveStatusText,
      listMateriales,
      materiales,
      proveedores,
      tableHeaders,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
